<template>
  <div>
    <b-modal
      id="change-state-material-order"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('ChangeStateOrder')"
      size="xl"
      hide-footer
      @close="onCancel"
      @shown="handleModalShown"
    >
      <b-form class="vacation">
        <div style="display:flex;gap:10px;width:100%">
          <div
            style="width:100%"
          >
            <b-form-group
              id="input-select-1"
              :label="`${$t('ChangeOrderState')}:`"
              label-for="select-1"
              style="width:100%"
            >
              <vue-select
                v-model="form.state"
                :options="states"
                label="text"
                :reduce="(e) => e.value"
                :placeholder="$t('SelectState') "
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </div>
          <div
            style="width:100%"
          >
            <b-form-group
              :label="$t('Account')"
              style="width:100%"
            >
              <vue-select
                v-model="accountId"
                :options="getAccountDetails"
                label="name"
                :reduce="(e) => e.accountId"
                :placeholder="$t('Select')"
              />
            </b-form-group>
            <p
              v-if="accountId == null && buttonSubmited && form.state == 'Received'"
              style="color:red;padding-top: 10px"
            >
              {{ $t('SelectAccount') }}
            </p>
          </div>
        </div>
        <div v-if="form.state === 'Received'">
          <table
            class="team_table"
          >
            <thead>
              <tr>
                <th>{{ $t('Variant') }}</th>
                <th>{{ $t('Amount') }}</th>
                <th>{{ $t('PriceNoVat') }}</th>
                <th>{{ $t('Rabat') }}</th>
                <th>{{ $t('Vat') }}</th>
                <th>{{ $t('PriceWithVat') }}</th>
                <th>{{ $t('AmountWithVat') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(material, index) in selectedMaterials"
                :key="index"
              >
                <td>{{ material.materialVariantName }}</td>
                <td>
                  <b-form-input
                    v-model.number="material.orderedAmount"
                    type="number"
                    :placeholder="$t('Amount')"
                    readonly
                    @input="calculateFromAmount(material)"
                  />
                </td>
                <td>
                  <b-form-input
                    v-model.number="material.priceWithoutVAT"
                    type="number"
                    :placeholder="$t('Value')"
                    :disabled="material.orderedAmount == 0 || material.orderedAmount == '' || material.orderedAmount == null"
                    @input="calculateFromPriceWithoutVAT(material)"
                  />
                  <p
                    v-if="material.validationPriceWithoutVAT == true && buttonSubmited == true"
                    style="color:lightcoral"
                  >
                    {{ $t('TheValue') }}
                  </p>
                </td>
                <td>
                  {{ material.rabaat }}
                </td>
                <td>
                  <b-form-radio-group
                    v-model="material.selectedVAT"
                    :options="vatOptions"
                    style="display:flex;gap: 5px;align-items: center;"
                    @input="calculateFromSelectedVAT(material)"
                  />
                </td>
                <td>
                  <b-form-input
                    v-model.number="material.priceWithVAT"
                    type="number"
                    :placeholder="$t('Value')"
                    :disabled="material.orderedAmount == 0 || material.orderedAmount == '' || material.orderedAmount == null"
                    @input="calculateFromPriceWithVAT(material)"
                  />
                  <p
                    v-if="material.validationPriceWithVAT == true && buttonSubmited == true"
                    style="color:lightcoral"
                  >
                    {{ $t('TheValue') }}
                  </p>
                </td>
                <td>
                  <b-form-input
                    v-model.number="material.valueWithVAT"
                    type="number"
                    :placeholder="$t('Value')"
                    :disabled="material.orderedAmount == 0 || material.orderedAmount == '' || material.orderedAmount == null"
                    @input="calculateFromValueWithVAT(material)"
                  />
                  <p
                    v-if="material.validationValueWithVAT == true && buttonSubmited == true"
                    style="color:lightcoral"
                  >
                    {{ $t('TheValue') }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-form-group
          id="input-group-8"
          :label="$t('Notes')"
          label-for="input-25"
        >
          <b-form-textarea
            id="textarea"
            v-model="form.notes"
            :placeholder="$t('Describe')"
            rows="3"
            max-rows="10"
          />
        </b-form-group>
        <AddFile
          v-if="form.state === 'Received'"
          ref="addFile"
          :folder="'OrderCartPhoto'"
          :label-text="'UploadInvoice'"
          :file-name="uploadPhotos"
        />
      </b-form>

      <div class="buttonsEverywhere">
        <b-button
          type="button"
          :disabled="loading || form.state == null"
          class="buttonSubmit"
          @click="onSubmit"
        >
          <div
            v-if="loading"
            class="spinner-border spinner-border-sm"
          />
          {{ $t("Submit") }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px;
                  background: white;
                  color: black;
                  border-color: white;
                  font-weight: 400;"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddFile from '@/modals/AddFile.vue';
import { validationMixin } from 'vuelidate';
import 'vue2-datepicker/index.css';

import { client } from '@/domainConfig'


export default {
  components: {
    AddFile,
  },
  mixins: [validationMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    orderCartId: {
      type: String,
    },
  },
  data() {
    return {
      accountId: null,
      loading: false,
      uploadPhotos: [],
      client: client.clientName,
      form: {
        state: null,
        notes: null,
      },
      receivedMaterialVariants: {
        materialVariantId: null,
        totalPrice: null,
      },
      states: [
        { value: 'Cancelled', text: this.$t('Cancelled') },
        { value: 'Received', text: this.$t('Received') },
      ],
      selectedMaterials: [],
      selectedVAT: 18,
      vatOptions: [
        { text: '18%', value: 18 },
        { text: '8%', value: 8 },
        { text: '0%', value: 0 },
      ],
      buttonSubmited: false,
    };
  },
  computed: {
    ...mapGetters([
      'getLoggedInUser',
      'getSuppliers',
      'getOrderItemsCartId',
      'getAccountDetails',
    ]),
    calculateAllTotal() {
      return this.getOrderItemsCartId.map((orderItem, key) => {
        return {
          totalPrice: this.receivedMaterialVariants[key],
          materialVariantId: orderItem.materialVariantId,
        };
      });
    },
    calculatingTotalPrice() {
      const values = Object.values(this.receivedMaterialVariants);
      return values.reduce((sum, value) => { return sum + Number(value) }, 0);
    },
  },
  watch: {
    'form.state': function (value) {
      if (value) {
        this.selectedMaterials = this.getOrderItemsCartId.map((item) => {
          return {
            ...item,
            valueWithVAT: 0,
            priceWithVAT: 0,
            rabaat: 0,
            priceWithoutVAT: 0,
            selectedVAT: this.selectedVAT,
            validationPriceWithoutVAT: true,
            validationPriceWithVAT: true,
            validationValueWithVAT: true,
          }
        });
      } else {
        this.selectedMaterials = [];
      }
    },
  },
  created() {
  },
  methods: {
    ...mapActions(['addFileMaterialOrder', 'loadOrderItemsByCardId', 'receiveMaterialVariants',
    ]),
    validateMaterials() {
      this.selectedMaterials.forEach((material) => {
        if (material.valueWithVAT <= 0 || material.valueWithVAT === null || material.valueWithVAT === '') {
          material.validationValueWithVAT = true;
        } else {
          material.validationValueWithVAT = false;
        }


        if (material.priceWithoutVAT <= 0 || material.priceWithoutVAT === null || material.priceWithoutVAT === '') {
          material.validationPriceWithoutVAT = true;
        } else {
          material.validationPriceWithoutVAT = false;
        }

        if (material.priceWithVAT <= 0 || material.priceWithVAT === null || material.priceWithVAT === '') {
          material.validationPriceWithVAT = true;
        } else {
          material.validationPriceWithVAT = false;
        }
      });
    },
    calculateFromAmount(material) {
      if (material.orderedAmount > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.orderedAmount).toFixed(2));
      }
      this.validateMaterials()
    },

    calculateFromPriceWithVAT(material) {
      if (material.orderedAmount > 0) {
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.orderedAmount).toFixed(2));
      } else {
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromSelectedVAT(material) {
      if (material.orderedAmount > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.orderedAmount).toFixed(2));
      } else if (material.priceWithoutVAT > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
      }

      if (material.priceWithVAT > 0) {
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromPriceWithoutVAT(material) {
      if (material.orderedAmount > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.orderedAmount).toFixed(2));
      } else {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromValueWithVAT(material) {
      if (material.orderedAmount > 0) {
        material.priceWithVAT = parseFloat((material.valueWithVAT / material.orderedAmount).toFixed(2));
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    removeMaterial(index) {
      this.selectedMaterials.splice(index, 1)
    },
    handleModalShown() {
    },
    calculateTotal(ordered, material) {
      return (ordered / material)
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },

    async onSubmit() {
      this.buttonSubmited = true;

      if (this.buttonSubmited) {
        this.selectedMaterials.forEach((material) => {
          if (
            material.valueWithVAT <= 0
      || material.valueWithVAT === null
      || material.valueWithVAT === ''
          ) {
            material.validationValueWithVAT = true;
          } else {
            material.validationValueWithVAT = false;
          }


          if (
            material.priceWithoutVAT <= 0
      || material.priceWithoutVAT === null
      || material.priceWithoutVAT === ''
          ) {
            material.validationPriceWithoutVAT = true;
          } else {
            material.validationPriceWithoutVAT = false;
          }

          if (
            material.priceWithVAT <= 0
      || material.priceWithVAT === null
      || material.priceWithVAT === ''
          ) {
            material.validationPriceWithVAT = true;
          } else {
            material.validationPriceWithVAT = false;
          }
        });
      }

      const hasErrors = this.selectedMaterials.some(
        (material) => {
          return material.validationValueWithVAT || material.validationPriceWithoutVAT || material.validationPriceWithVAT
        },
      );
      if ((this.selectedMaterials.length === 0 || hasErrors || this.accountId == null) && this.form.state == 'Received') {
        return
      }
      try {
        this.loading = true;
        await this.$refs.addFile.uploadFile();
        this.addFileMaterialOrder({
          orderCartId: this.orderCartId,
          object: this.uploadPhotos,
        })
        this.$refs.modal.hide()
        setTimeout(() => {
          this.onReset()
        }, 10)
      } catch (error) {
        console.error('An error occurred during file upload:', error);
      } finally {
        this.loading = false; // Disable the button

        const objekti = {
          orderCartId: this.orderCartId,
          notes: this.form.notes,
          accountId: this.accountId,
          receivedMaterialVariants: this.selectedMaterials.map((item) => {
            return {
              materialVariantId: item.materialVariantId,
              totalPrice: item.valueWithVAT,
              taxRate: item.selectedVAT,
              receivedAmount: item.orderedAmount,
            }
          }),
        }
        const objekti2 = {
          orderCartId: this.orderCartId,
          state: this.form.state,
          notes: this.form.notes,
        }
        this.$emit('changeState', objekti2);
        if (this.form.state != 'Cancelled') {
          this.$emit('totalPrice', objekti);
        }
        this.uploadPhotos = []
      }
      this.$refs.modal.hide();
      this.onReset();
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onReset() {
      this.receivedMaterialVariants = {}
      this.form.notes = ''
      this.form.state = null
      this.uploadPhotos = []
      this.accountId = null
    },
  },
};
</script>

      <style lang="scss" scoped>
      form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
      }
      .form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
      }
      .vacation {
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr;
      }
      .form2 {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
      }
      .flex-form {
        display: flex;
        flex-direction: column;
      }
      .uploadButtons {
        display: flex;
        align-items: flex-end;
      }
      </style>
