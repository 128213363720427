<template>
  <b-modal
    id="add-order-material-file"
    ref="modal"
    header-class="headerModal"
    centered
    hide-footer
    size="l"
    title="Add File"
    @close="onCancel"
  >
    <AddFile
      ref="addFile"
      :folder="'OrderCartPhoto'"
      :label-text="'UploadPhoto'"
      :file-name="uploadPhotos"
    />
    <div class="buttonsEverywhere">
      <b-button
        type="button"
        variant="none"
        :disabled="loading"
        style="margin-left: 0px; background: #FF274F !important;"
        class="buttonSubmit"
        @click="onSubmit"
      >
        <div
          v-if="loading"
          class="spinner-border spinner-border-sm"
        />
        {{ $t('Confirm') }}
      </b-button>
      <b-button
        type="reset"
        variant="danger"
        class="buttonCancel"
        style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
        @click="onCancel"
      >
        {{ $t('Cancel') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import AddFile from '@/modals/AddFile.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    AddFile,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['orderCartId', 'pageSize', 'page', 'selectedOrderState', 'selectedMonth', 'selectedYear'],
  data() {
    return {
      uploadPhotos: [],
      loading: false,
    }
  },
  methods: {
    ...mapActions(['addFileMaterialOrder', 'loadOrderedMaterials']),
    async onSubmit() {
      try {
        this.loading = true;
        await this.$refs.addFile.uploadFile();
        this.addFileMaterialOrder({
          orderCartId: this.orderCartId,
          object: this.uploadPhotos,
          successCallback: () => {
            this.loadOrderedMaterials({
              orderCartType: 'Cart',
              state: this.selectedOrderState,
              month: this.selectedMonth ? new Date(`${this.selectedMonth} 1`).toLocaleDateString(undefined, { month: '2-digit' }) : null,
              year: this.selectedYear,
              pageNumber: this.page,
              pageSize: this.pageSize,
            })
          },
        })
        this.$refs.modal.hide()
        setTimeout(() => {
          this.resetForm()
        }, 10)
      } catch (error) {
        console.error('An error occurred during file upload:', error);
      } finally {
        this.loading = false; // Disable the button
        this.uploadPhotos = []
      }
      // this.form.reset();
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => {
        this.resetForm()
      }, 10)
    },
    resetForm() {
      this.uploadPhotos = []
    },
  },
};
</script>

  <style scoped lang="scss">
  </style>
